<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="isAddPlaceDialogVisible"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title
            class="main-header justify-space-between primary mb-4"
        >
          <span
              class="headline"
              style="color: #fff"
          >
            {{ $t('licensesPage.add_place') }}
          </span>
          <v-btn
              @click="closeDialog"
              large
              icon
              color="white"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent
                lazy-validation
            >
              <v-row
                  class="d-flex"
              >
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('licensesPage.name')+'*'"
                      v-model="form.name"
                      :rules="rules.required"
                      :disabled="!cities"
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('licensesPage.address')+'*'"
                      v-model="form.address"
                      :rules="rules.required"
                      :disabled="!cities"
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-autocomplete
                      v-model="city"
                      :items="cities"
                      :label="$t('licensesPage.city')+'*'"
                      return-object
                      :no-data-text="$t('licensesPage.no_data')"
                      :rules="rules.required"
                      :loading="!cities"
                      :disabled="!cities"
                      item-text="name"
                      dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>
            {{ $t('licensesPage.required_fields') + ' *' }}
          </small>
        </v-card-text>
        <v-card-actions
            class="justify-space-between"
        >
          <v-btn
              @click="clearInputs"
              :disabled="!cities"
              color="red darken-1"
              text
          >
            {{ $t('licensesPage.delete') }}
          </v-btn>
          <v-btn
              @click="add()"
              :loading="loading"
              :disabled="!cities"
              color="blue darken-1"
              text
          >
            {{ $t('licensesPage.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "AddPlaceDialog",
  props: {
    isAddPlaceDialogVisible: false,
    partnerId: 0,
  },
  data() {
    return {
      cities: null,
      city: null,
      citySearch: '',
      loading: false,
      params: {
        "show_only_active": false
      },
      form: {
        name: '',
        address: '',
        city_id: '',
        partner_id: this.partnerId || ''
      },
      rules: {
        required: [
          v => !!v || this.$t('licensesPage.required_fields'),
        ]
      },
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },
  methods: {
    clearInputs() {
      this.form = {
        name: '',
        address: '',
        city_id: '',
        city: null
      };
      this.$refs.form.resetValidation();
    },

    closeDialog() {
      this.form = {
        name: '',
        address: '',
        city_id: '',
        city: null
      };
      this.$refs.form.resetValidation();
      this.$emit('close');
    },

    getCities() {
      axios.post('/cities/index', this.params).then(response => {
        this.cities = response.data;
      }).catch(error => {
        console.log(error);
      })
    },

    add() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.post('/places/store', this.form).then((response) => {
          this.$emit('addEmit', response.data);
          this.closeDialog();
        }).catch(error => {
          this.loading = false;
          if(!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
            })
            this.$router.push('/login')
          }
        })
      }catch (error){
        console.log(error)
      }
    }
  },
  created() {
    this.getCities();
  },
  watch: {
    city: {
      handler: function (value) {
        if (value) {
          this.form.city_id = value.id;
        } else {
          this.form.city_id = '';
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>