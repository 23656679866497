<template>
  <v-container
      fluid
      grid-list-sm
  >
    <v-toolbar
        color="white"
    >
      <v-toolbar-title
          class="headline"
      >
        {{ $t('partnersPage.partners') }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn
          v-show="!tableDataLoading"
          @click="openUpdateAndAddPartnerDialog(true, null)"
          class="mr-4"
          color="primary"
          dark
      >
        {{ $t('partnersPage.add_partner') }}
      </v-btn>
      <v-btn
          @click="readTableData(api, params)"
          :loading="tableDataLoading"
          :disabled="tableDataLoading"
          color="warning"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-spacer/>
    <v-data-table
        :mobile-breakpoint="breakpoint"
        :headers="headersFinal"
        :items="tableData"
        :items-per-page="itemsPerPage"
        :footer-props="{itemsPerPageOptions: [10,20,30,50,-1]}"
        :loading-text="$t('partnersPage.loading_data')"
        :loading="tableDataLoading"
        :key="anIncreasingNumber"
        :dense="true"
        class="elevation-1"
        sort-by="name"
    >
      <template
          v-slot:[`item.is_active`]="{ item }"
      >
        <v-chip
            small
            :class="{'error inactive-row white--text':!item.is_active, 'white black--text':item.is_active}"
        >
          {{ item.is_active ? $t('partnersPage.is_active') : $t('partnersPage.not_active') }}
        </v-chip>
      </template>
      <template
          v-slot:[`item.actions`]="{ item }"
      >
        <v-layout
            align-center
            justify-center
        >
          <v-tooltip
              bottom
          >
            <template
                v-slot:activator="{ on, attrs }"
            >
              <v-btn
                  @click.stop="openShowPlacesDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                  icon
              >
                <v-icon
                    style="color: #0056b3"
                >
                  mdi-account-edit
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('partnersPage.add_place') }}
            </span>
          </v-tooltip>
          <v-tooltip
              bottom
          >
            <template
                v-slot:activator="{ on, attrs }"
            >
              <v-btn
                  @click.stop="openUpdateAndAddPartnerDialog(true, item)"
                  v-bind="attrs"
                  v-on="on"
                  icon
              >
                <v-icon
                    style="color: #ffa21a"
                >
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('partnersPage.edit_partner') }}
            </span>
          </v-tooltip>
          <v-tooltip
              bottom
          >
            <template
                v-slot:activator="{ on, attrs }"
            >
              <v-btn
                  @click.stop="onDelete(destroyApi,item)"
                  :loading="deleteLoadingId === item.id"
                  v-bind="attrs"
                  v-on="on"
                  icon
              >
                <v-icon
                    v-if="item.is_active"
                    class="red--text"
                >
                  mdi-trash-can-outline
                </v-icon>
                <v-icon
                    v-else
                >
                  mdi-autorenew
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ item.is_active ? $t('partnersPage.deactivate_partner') : $t('partnersPage.reactivate_partner') }}
            </span>
          </v-tooltip>
        </v-layout>
      </template>
      <template
          v-slot:[`body.prepend`]
      >
        <tr
            class="grey lighten-2"
        >
          <th
              v-for="header in headers"
              :key="header.name"
              class="py-1"
          >
            <v-select
                v-if="header.name === 'active'"
                v-model="filters[header.value]"
                @change="search"
                :items="activeObjects"
                item-value="value"
                item-text="text"
                class="justify-center"
                style="padding: 0; margin-bottom: -1em !important; font-size: inherit; text-align: right !important;"
            />
            <v-text-field
                v-else-if="header.name !== 'actions'"
                v-model="filters[header.value]"
                @keyup.enter.native="search"
                :placeholder="header.text"
                hide-details
                style="padding: 0; margin-top: -1em !important; font-size: inherit; text-align: right !important;"
            />
            <v-layout
                v-else
                class="justify-center"
            >
              <v-btn
                  @click="search"
                  :disabled="tableDataLoading"
                  color="primary"
                  depressed
                  small
              >
                <v-icon>
                  mdi-search-web
                </v-icon>
              </v-btn>
              <v-btn
                  @click="refresh(true)"
                  :disabled="tableDataLoading"
                  depressed
                  color="red"
                  class="ml-1"
                  small
              >
                <v-icon
                    color="white"
                >
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </v-layout>
          </th>
        </tr>
      </template>
      <template
          v-slot:[`no-data`]
      >
        <div
            class="text-xs-center pt-3"
        >
          <v-alert
              color="red"
          >
            <span
                class="flex items-center justify-center"
                style="color: white"
            >
              <span>
                {{ $t('partnersPage.no_data') }}
              </span>
              <v-icon
                  class="white-icon ml-2"
              >
                mdi-alert-circle-outline
              </v-icon>
            </span>
          </v-alert>
        </div>
      </template>
    </v-data-table>
    <partner-update-and-add-dialog
        v-if="isOpenUpdateAndAddPartnerDialogVisible"
        :isDialogVisible="isOpenUpdateAndAddPartnerDialogVisible"
        :dataForDialog="dataForUpdateAndAddPartnerDialog"
        @close="isOpenUpdateAndAddPartnerDialogVisible=false; dataForUpdateAndAddPartnerDialog={}"
        @addEmit="addEmit"
        @editEmit="editEmit"
    />
    <places-show-dialog
        v-if="isShowPlacesDialogVisible"
        :isPlacesDialogVisible="isShowPlacesDialogVisible"
        :partnerId="dataForShowPlacesDialog.id"
        :partnerName="dataForShowPlacesDialog.name"
        @close="isShowPlacesDialogVisible=false"
        @addEmit="addEmit"
        @editEmit="editEmit"
    />
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  readTableData,
  search,
  refresh,
  addEmit,
  editEmit,
  findById,
  onDelete
} from '@/statics/DataTableFunctions';

import PartnerDialog from "@/components/partners/partnerAddAndUpdateDialog/partnerAddAndUpdateDialog.vue";
import PlacesDialog from "@/components/partners/placeDialog/placeDialog.vue";

export default {
  name: "PartnersPage",
  components: {'partner-update-and-add-dialog': PartnerDialog, 'places-show-dialog': PlacesDialog},
  data() {
    return {
      destroyApi: 'partners/destroy',
      breakpoint: 320,
      tableData: [],
      api: 'partners/index',
      params: {"show_only_active": false},
      itemsPerPage: 10,
      tableDataLoading: false,
      anIncreasingNumber: 1,
      filters: {is_active: ''},
      deleteLoadingId: -1,
      headers: [],
      isOpenUpdateAndAddPartnerDialogVisible: false,
      dataForUpdateAndAddPartnerDialog: {},
      isShowPlacesDialogVisible: false,
      dataForShowPlacesDialog: {},
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    };
  },
  methods: {
    onDelete,
    editEmit,
    addEmit,
    readTableData,
    search,
    refresh,
    findById,
    openUpdateAndAddPartnerDialog(isVisible, item) {
      this.isOpenUpdateAndAddPartnerDialogVisible = isVisible;
      this.dataForUpdateAndAddPartnerDialog = item;
    },

    openShowPlacesDialog(item) {
      this.dataForShowPlacesDialog = item;
      this.isShowPlacesDialogVisible = true;
    }
  },

  computed: {
    headersFinal: {
      get() {
        return this.headers;
      },
      set(newValue) {
        this.headers = newValue;
      }
    },
    activeObjects() {
      return [
        {value: "", text: this.$t('partnersPage.all')},
        {value: true, text: this.$t('partnersPage.is_active')},
        {value: false, text: this.$t('partnersPage.not_active')}
      ];
    }
  },

  created() {
    this.headersFinal = [
      {
        text: this.$t('partnersPage.name'),
        name: 'name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: this.$t('partnersPage.address'),
        name: 'address',
        align: 'left',
        sortable: true,
        value: 'address'
      },
      {
        text: this.$t("partnersPage.city"),
        name: "city_name",
        align: "left",
        sortable: true,
        value: "city.name"
      },
      {
        text: this.$t('partnersPage.telephone'),
        name: 'telephone',
        align: 'left',
        sortable: true,
        value: 'telephone'
      },
      {
        text: this.$t('partnersPage.email'),
        name: 'email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: this.$t('partnersPage.contact_person'),
        name: 'contact_person',
        align: 'left',
        sortable: true,
        value: 'contact_person',
        width: 150
      },
      {
        text: this.$t('partnersPage.account_no'),
        name: 'account_no',
        align: 'left',
        sortable: true,
        value: 'account_no'
      },
      {
        text: this.$t('partnersPage.pib'),
        name: 'pib',
        align: 'left',
        sortable: true,
        value: 'pib'
      },
      {
        text: this.$t('partnersPage.pdv'),
        name: 'pdv',
        align: 'left',
        sortable: true,
        value: 'pdv'
      },
      {
        text: this.$t('partnersPage.vendor_code'),
        name: 'sif_dob',
        align: 'left',
        sortable: true,
        value: 'sif_dob',
        width: 150
      },
      {
        text: this.$t('partnersPage.active'),
        name: 'active',
        align: 'left',
        sortable: true,
        value: 'is_active'
      },
      {text: this.$t('partnersPage.actions'), name: 'actions', align: 'center', sortable: false, value: 'actions'},
    ]
    this.readTableData(this.api, this.params, {});
  }
}
</script>

<style scoped>
.v-toolbar {
  margin-bottom: 15px;
}

.white-icon {
  color: white;
}
</style>