<template>
  <v-container
      fluid
      grid-list-sm
  >
    <v-toolbar
        color="white"
    >
      <v-toolbar-title
          class="headline"
      >
        {{ $t('licensesPage.licenses') }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn
          class="mr-4"
          color="primary"
          dark
          @click="openLicenceUpdateDialog(true, null)" v-show="!tableDataLoading"
      >
        {{ $t('licensesPage.add_licence') }}
      </v-btn>
      <v-btn
          :loading="tableDataLoading"
          :disabled="tableDataLoading"
          color="warning"
          @click="read(api)"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-spacer/>
    <v-data-table
        :mobile-breakpoint="breakpoint"
        :headers="headersFinal"
        :items="tableData"
        :items-per-page="itemsPerPage"
        :footer-props="{itemsPerPageOptions: [10,20,30,50,-1]}"
        :dense="true"
        :loading-text="$t('licensesPage.loading_data')"
        :loading="tableDataLoading"
        :key="anIncreasingNumber"
        class="elevation-1"
        sort-by="partner_licence_data_updated_at"
        sort-desc
    >
      <template
          v-slot:[`item.status`]="{ item }"
      >
        <v-chip
            small
            :class="{
        'error white--text': item.status === 'Opozvana',
        'blue white--text': item.status === 'Aktivna',
        'orange white--text': item.status === 'Suspendovana',
        'dark white--text': item.status !== 'Kreirana'}"
        >
          {{
            item.status === 'Aktivna' ? $t('licensesPage.is_active') : item.status === 'Kreirana' ? $t('licensesPage.created') : item.status === 'Suspendovana' ? $t('licensesPage.suspended') : item.status === 'Opozvana' ? $t('licensesPage.not_active') : ''
          }}
        </v-chip>
      </template>
      <template
          v-slot:[`item.actions`]="{ item }"
      >
        <v-layout
            align-center
            justify-center
        >
          <v-btn
              icon
              @click.stop="openLicenceUpdateDialog(true, item)"
          >
            <v-icon
                style="color: #ffa21a"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </v-layout>
      </template>
      <template
          v-slot:[`body.prepend`]
      >
        <tr
            class="grey lighten-2"
        >
          <th
              v-for="header in headers"
              :key="header.name"
              class="py-1"
          >
            <v-text-field
                v-if="header.name !== 'actions'"
                v-model="filters[header.value]"
                @keyup.enter.native="search"
                :placeholder="header.text"
                hide-details
                class="filter-input"
            />
            <v-layout
                v-else
                class="justify-center"
            >
              <v-btn
                  @click="search"
                  :disabled="tableDataLoading"
                  color="primary"
                  depressed
                  small
              >
                <v-icon>
                  mdi-search-web
                </v-icon>
              </v-btn>
              <v-btn
                  @click="refresh(true)"
                  :disabled="tableDataLoading"
                  depressed
                  class="ml-1"
                  small
                  color="red"
              >
                <v-icon
                    color="white"
                >
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </v-layout>
          </th>
        </tr>
      </template>
      <template
          v-slot:[`no-data`]
      >
        <div
            class="text-xs-center pt-3"
        >
          <v-alert
              color="red"
          >
            <span
                class="flex items-center justify-center"
                style="color: white"
            >
              <span>
                {{ $t('partnersPage.no_data') }}
              </span>
              <v-icon
                  class="white-icon ml-2"
              >
                mdi-alert-circle-outline
              </v-icon>
            </span>
          </v-alert>
        </div>
      </template>
    </v-data-table>
    <licence-update-dialog
        v-if="isAddLicenceDialogVisible"
        :isUpdateLicenceDialogVisible="isAddLicenceDialogVisible"
        :dataForDialog="dataForUpdateLicenceDialog"
        @close="isAddLicenceDialogVisible=false"
        @addEmit="addEmit"
        @editEmit="editEmit"
    />
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  read,
  search,
  refresh,
  addEmit,
  editEmit,
  findById
} from '@/statics/DataTableFunctions';

import LicenceDialog from "@/components/licenses/licenceAddAndUpdateDialog.vue/licenceAddAndUpdateDialog.vue";

export default {
  name: "Licenses",
  components: {'licence-update-dialog': LicenceDialog},
  data() {
    return {
      breakpoint: 320,
      tableData: [],
      api: 'licenses/all_data',
      itemsPerPage: 10,
      tableDataLoading: false,
      anIncreasingNumber: 1,
      filters: {
        is_active: '',
      },
      headers: [],
      isAddLicenceDialogVisible: false,
      isUpdateLicenceDialogVisible: false,
      dataForUpdateLicenceDialog: {},
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    };
  },
  methods: {
    editEmit,
    addEmit,
    read,
    search,
    refresh,
    findById,

    openLicenceUpdateDialog(isVisible, item) {
      this.isAddLicenceDialogVisible = isVisible;
      this.dataForUpdateLicenceDialog = item;
    }
  },
  computed: {
    headersFinal: {
      get() {
        return this.headers;
      },
      set(newValue) {
        this.headers = newValue;
      }
    }
  },

  created() {
    this.headersFinal = [
      {
        text: this.$t('licensesPage.updated_at'),
        name: 'partner_licence_data_updated_at',
        align: 'left',
        sortable: true,
        value: 'partner_licence_data_updated_at'
      },
      {
        text: this.$t('licensesPage.licence'),
        name: 'licence',
        align: 'left',
        sortable: true,
        value: 'licenca'
      },
      {
        text: this.$t('licensesPage.partner'),
        name: 'partner',
        align: 'left',
        sortable: true,
        value: 'partner'
      },
      {
        text: this.$t("licensesPage.valid_from"),
        name: "valid_from",
        align: "left",
        sortable: true,
        value: "vazi_od"
      },
      {
        text: this.$t('licensesPage.valid_to'),
        name: 'valid_to',
        align: 'left',
        sortable: true,
        value: 'vazi_do'
      },
      {
        text: this.$t('licensesPage.enu'),
        name: 'enu',
        align: 'left',
        sortable: true,
        value: 'enu'
      },
      {
        text: this.$t('licensesPage.status'),
        name: 'status',
        align: 'left',
        sortable: true,
        value: 'status'
      },
      {
        text: this.$t('licensesPage.place'),
        name: 'place',
        align: 'left',
        sortable: true,
        value: 'mjesto'
      },
      {text: this.$t('licensesPage.actions'), name: 'actions', align: 'center', sortable: false, value: 'actions'},
    ]
    this.read(this.api);
  }
}
</script>

<style scoped>
.v-toolbar {
  margin-bottom: 15px;
}

.white-icon {
  color: white;
}

.filter-input {
  padding: 12px 0;
  margin-top: -1em !important;
  font-size: inherit;
  text-align: right !important;
}
</style>
