<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="isDialogVisible"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title
            class="main-header justify-space-between primary mb-4"
        >
          <span
              class="headline"
              style="color:#fff"
          >
            {{ dataForDialog === null ? $t('countriesPage.add_country') : $t('countriesPage.edit_country') }}
          </span>
          <v-btn
              @click="closeDialog"
              color="white"
              large
              icon
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent
                lazy-validation
            >
              <v-row>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('countriesPage.name')+'*'"
                      v-model="form.name"
                      :rules="rules.required"
                      dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>
            {{ $t('countriesPage.required_fields') + ' *' }}
          </small>
        </v-card-text>
        <v-card-actions
            class="justify-space-between"
        >
          <v-btn
              @click="clearInputs"
              color="red darken-1"
              text
          >
            {{ $t('countriesPage.delete') }}
          </v-btn>
          <v-btn
              @click="dataForDialog === null ? add() : edit()"
              :loading="loading"
              style="color: #0056b3"
              text
          >
            {{ dataForDialog === null ? $t('countriesPage.add') : $t('countriesPage.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "CountryUpdateAndAddDialog",
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    dataForDialog: {}
  },
  data() {
    return {
      loading: false,
      itemHeaders: null,
      form: {
        name: this.dataForDialog !== null ? this.dataForDialog.name : ''
      },
      rules: {
        required: [
          v => !!v || this.$t('countriesPage.required_fields'),
        ]
      },
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },
  methods: {
    clearInputs() {

      this.$refs.form.reset();
    },

    closeDialog() {
      this.form = {
        name: ''
      };
      this.$refs.form.resetValidation();
      this.$emit('close');
    },

    add() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.post('/countries/store', this.form).then((response) => {
          this.$emit('addEmit', response.data);
          this.closeDialog();
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
            this.$router.push('/login')
          }
        })
      }catch (error){
        console.log(error)
      }
    },

    edit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.put(`/countries/update/${this.dataForDialog.id}`, this.form).then((response) => {
          this.$emit('editEmit', response.data)
          this.closeDialog();
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
            })
            this.$router.push('/login')
          }
        })
      }catch (error){
        console.log(error)
      }
    }
  }
}

</script>

<style scoped>
.main-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1
}
</style>