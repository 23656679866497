import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from "@/views/auth/loginPage.vue";
import DashboardPage from "@/views/dashboard/dashboard.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import PartnersPage from "@/views/partners/partners.vue";
import NotFound from "@/views/notFound/notFound.vue";
import LicensesPage from "@/views/licenses/licenses.vue";
import CountriesPage from "@/views/countries/countries.vue";
import CitiesPage from "@/views/cities/cities.vue";

Vue.use(VueRouter)

const routes = [
    // Ruta za prijavljivanje
    {
        path: '/login',
        name: "LoginPage",
        component: PublicLayout,
        children: [
            {
                path: "",
                component: LoginPage
            }
        ]
    },
    // Glavne rute za aplikaciju
    {
        path: '/',
        name: "DashboardPage",
        component: DashboardLayout,
        children: [
            // Ruta za glavni kontrolni panel
            {
                path: "/dashboard",
                component: DashboardPage,
                meta: { requiresAuth: true }
            },
            // Ruta za partnerske informacije
            {
                path: "/partners",
                component: PartnersPage,
                meta: { requiresAuth: true }
            },
            // Ruta za informacije o licencama
            {
                path: "/licenses",
                component: LicensesPage,
                meta: { requiresAuth: true }
            },
            // Ruta za informacije o državama
            {
                path: "/countries",
                component: CountriesPage,
                meta: { requiresAuth: true }
            },
            // Ruta za informacije o gradovima
            {
                path: "/cities",
                component: CitiesPage,
                meta: { requiresAuth: true }
            }
        ]
    },
    // Ruta za "404 Not Found" stranicu
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// Globalni beforeEach hook za navigaciju
router.beforeEach((to, from, next) => {
    // Provjerava da li je korisnik autentifikovan (na osnovu prisutnog tokena)
    const isAuthenticated = localStorage.getItem('token') !== null;

    // Logika za preusmjeravanje korisnika u zavisnosti od trenutne rute i autentifikacije
    if (to.path === '/') {
        if (isAuthenticated) {
            next('/dashboard');
        } else {
            next('/login');
        }
    } else if (to.path === '/login') {
        if (isAuthenticated) {
            next('/dashboard');
        } else {
            next(); // Nastavak normalne navigacije ako nije autentifikovan
        }
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isAuthenticated) {
            next();
        } else {
            next('/login');
        }
    } else {
        next();
    }
})

export default router;
