<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="addItemDialog"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title
            class="main-header justify-space-between primary mb-4"
        >
          <span
              class="headline"
              style="color:#fff"
          >
            {{ dataForDialog === null ? $t('citiesPage.add_city') : $t('citiesPage.edit_city') }}
          </span>
          <v-btn
              @click="closeDialog"
              color="white"
              large
              icon
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent
                lazy-validation
            >
              <v-row>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('citiesPage.name')+'*'"
                      v-model="form.name"
                      :rules="rules.required"
                      :disabled="!countries"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('citiesPage.post_code')+'*'"
                      v-model="form.post_code"
                      :rules="rules.required"
                      :disabled="!countries"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-autocomplete
                      :label="$t('citiesPage.country')+'*'"
                      v-model="country"
                      :rules="rules.required"
                      :items="countries"
                      :no-data-text="$t('citiesPage.no_data')"
                      :disabled="!countries"
                      :loading="!countries"
                      return-object
                      item-text="name"
                      dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>
            {{ $t('citiesPage.required_fields') + ' *' }}
          </small>
        </v-card-text>
        <v-card-actions
            class="justify-space-between"
        >
          <v-btn
              @click="clearInputs"
              :disabled="!countries"
              color="red darken-1"
              text
          >
            {{ $t('citiesPage.delete') }}
          </v-btn>
          <v-btn
              @click="dataForDialog === null ? add() : edit()"
              :disabled="!countries"
              :loading="loading"
              style="color:#0056b3"
              text
          >
            {{ dataForDialog === null ? $t('citiesPage.add') : $t('citiesPage.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "CityDialog",
  props: {
    addItemDialog: {
      type: Boolean,
      default: false
    },
    dataForDialog: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      loading: false,
      itemHeaders: null,
      countries: null,
      country: null,
      countrySearch: '',
      api: 'countries/index',
      params: {
        "show_only_active": 1
      },
      form: {
        name: this.dataForDialog !== null ? this.dataForDialog.name : '',
        post_code: this.dataForDialog !== null ? this.dataForDialog.post_code : '',
        country_id: this.dataForDialog !== null ? this.dataForDialog.country_id : '',
      },
      rules: {
        required: [
          v => !!v || this.$t('citiesPage.required_fields'),
        ]
      },
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },

  methods: {
    clearInputs() {
      this.$refs.form.reset();
    },

    setCountryById(countryId) {
      const foundCountry = this.countries.find(country => country.id === countryId);
      if (foundCountry) {
        this.country = foundCountry;
      }
    },

    getCountries() {
      axios.post('/countries/index', this.params)
          .then(response => {
            this.countries = response.data;
            if (this.dataForDialog !== null) {
              this.setCountryById(this.dataForDialog.country_id);
            }
          })
          .catch(error => {
            console.log(error);
          });
    },

    closeDialog() {
      this.form = {
        name: '',
        post_code: '',
        country_id: ''
      };
      this.$refs.form.resetValidation();
      this.$emit('close');
    },

    add() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.post('/cities/store', this.form).then((response) => {
          this.$emit('addEmit', response.data);
          this.closeDialog();
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
            this.$router.push('/login')
          }
        })
      }catch (error){
        console.log(error)
      }
    },

    edit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.put(`/cities/update/${this.dataForDialog.id}`, this.form).then((response) => {
          this.$emit('editEmit', response.data)
          this.closeDialog();
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
            this.$router.push('/login')
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  },

  created() {
    this.getCountries();
  },

  watch: {
    country: {
      handler: function (value) {
        if (value) {
          this.form.country_id = value.id;
        } else {
          this.form.country_id = '';
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>