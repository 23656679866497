import { render, staticRenderFns } from "./placeDialog.vue?vue&type=template&id=bbd9bfec&scoped=true&"
import script from "./placeDialog.vue?vue&type=script&lang=js&"
export * from "./placeDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd9bfec",
  null
  
)

export default component.exports