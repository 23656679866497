<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="isUpdateLicenceDialogVisible"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title
            class="main-header justify-space-between primary mb-4"
        >
          <span
              class="headline"
              style="color: #fff"
          >
            {{ dataForDialog !== null ? $t('licensesPage.edit_licence') : $t('licensesPage.add_licence') }}
          </span>
          <v-btn
              @click="closeDialog"
              large
              icon
              color="white"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent
                lazy-validation
            >
              <v-row
                  class="d-flex"
              >
                <v-col
                    cols="6"
                >
                  <v-autocomplete
                      v-model="form.partner_id"
                      :items="partners"
                      :label="$t('licensesPage.choose_partner')+'*'"
                      :rules="rules.required"
                      :no-data-text="$t('licensesPage.no_data')"
                      :loading="!partners"
                      :disabled="!partners"
                      item-value="id"
                      item-text="name"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('licensesPage.enu')"
                      v-model="form.enu"
                      :disabled="!partners"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-autocomplete
                      v-model="form.place_id"
                      :items="places"
                      :label="$t('licensesPage.place')+'*'"
                      :rules="form.partner_id ? rules.required : []"
                      :no-data-text="$t('licensesPage.no_data')"
                      :disabled="!places || loadingPlaces"
                      :loading="loadingPlaces"
                      item-value="id"
                      item-text="name"
                      dense
                  />
                </v-col>
                <v-col cols="3">
                  <v-btn
                      @click="addItemDialog=true"
                      :disabled="!places || loadingPlaces"
                      color="primary"
                      dense
                  >
                    <v-icon
                        left
                    >
                      mdi-plus
                    </v-icon>
                    {{ $t('licensesPage.add') }}
                  </v-btn>
                </v-col>
                <v-col
                    v-if="dataForDialog"
                    cols="6"
                >
                  <v-autocomplete
                      v-model="form.status_id"
                      :items="statuses"
                      :label="$t('licensesPage.status')+'*'"
                      :rules="form.partner_id ? rules.required : []"
                      :no-data-text="$t('licensesPage.no_data')"
                      :disabled="!statuses"
                      :loading="!statuses"
                      item-value="id"
                      item-text="name"
                      dense
                  />
                </v-col>
                <v-col
                    v-if="dataForDialog"
                    cols="6"
                />
                <v-col cols="6">
                  <v-menu
                      v-model="isVisibleFrom"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="331px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="form.available_from"
                          :rules="[...rules.required, ...rules.dateToValidation]"
                          :label="$t('licensesPage.valid_from')+'*'"
                          :disabled="!partners"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                      />
                    </template>
                    <v-date-picker
                        v-model="datePickerFrom"
                        :min="new Date().toISOString().substr(0, 10)"
                        :first-day-of-week="1"
                        locale="sr-Latn-CS"
                        width="331px"
                        dense
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                      v-model="isVisibleTo"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="331px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="form.available_to"
                          :label="$t('licensesPage.valid_to')+'*'"
                          :rules="[...rules.required, ...rules.dateToValidation]"
                          :disabled="!partners"
                          prepend-icon="mdi-calendar"
                          v-on="on"
                          outlined
                          readonly
                          dense
                      />
                    </template>
                    <v-date-picker
                        v-model="datePickerTo"
                        :min="new Date().toISOString().substr(0, 10)"
                        :first-day-of-week="1"
                        locale="sr-Latn-CS"
                        width="331px"
                        dense
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>
            {{ $t('licensesPage.required_fields') + ' *' }}
          </small>
        </v-card-text>
        <v-card-actions
            class="justify-space-between"
        >
          <v-btn
              @click="clearInputs"
              :disabled="!partners"
              color="red darken-1"
              text
          >
            {{ $t('licensesPage.delete') }}
          </v-btn>
          <v-btn
              @click="addOrEdit()"
              :disabled="!partners"
              :loading="loading"
              style="color: #0056b3"
              text
          >
            {{ dataForDialog !== null ? $t('licensesPage.edit_licence') : $t('licensesPage.add_licence') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-add-place
        v-if="addItemDialog"
        :isAddPlaceDialogVisible="addItemDialog"
        :partnerId="this.form.partner_id"
        @close="addItemDialog=false"
        @addEmit="addPlace"
    />
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import AddPlaceDialog from "@/components/licenses/placeDialog/placeDialog.vue";
import {formatDate} from "@/statics/DataTableFunctions";
import Vue from "vue";

export default {
  components: {
    'dialog-add-place': AddPlaceDialog
  },
  name: "LicenceDialog",
  props: {
    isUpdateLicenceDialogVisible: {
      type: Boolean,
      default: false
    },
    dataForDialog: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      loading: false,
      itemHeaders: null,
      loadingBtn: false,
      addItemDialog: false,
      places: null,
      statuses: null,
      chosenPlace: null,
      loadingPlaces: false,
      partnerName: this.dataForDialog !== null ? this.dataForDialog.partner : '',
      partners: null,
      loadingLicence: false,
      datePickerFrom: '',
      datePickerTo: '',
      isVisibleFrom: '',
      isVisibleTo: '',
      daysOfWeek: ['Nedelja', 'Ponedeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
      api: 'partners/index',
      params: {
        "show_only_active": false
      },
      form: {
        id: null,
        available_from: this.dataForDialog !== null ? this.dataForDialog.vazi_od : null,
        available_to: this.dataForDialog !== null ? this.dataForDialog.vazi_do : null,
        enu: this.dataForDialog !== null ? this.dataForDialog.enu : '',
        partner_id: this.dataForDialog !== null ? this.dataForDialog.partner_id : null,
        place_id: this.dataForDialog !== null ? this.dataForDialog.place_id : null,
        status_id: this.dataForDialog ? this.dataForDialog.status_id : null
      },
      rules: {
        required: [
          v => !!v || this.$t('licensesPage.required_fields'),
        ],
        dateToValidation: [
          date => date >= this.datePickerFrom || this.$t('licensesPage.date_to_before_date_from'),
        ]
      },
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },

  methods: {
    formatDate,
    clearInputs() {
      this.$refs.form.reset();
      this.loadingBtn = false;
    },

    closeDialog() {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },

    addPlace(item) {
      this.places.push(item);
      this.chosenPlace = item.name;
      this.form.place_id = item.id
      this.snackbar.color = 'success';
      this.snackbar.text = this.$t('general.add_success');
      this.snackbar.show = true;
    },

    addOrEdit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      if (!this.form.status_id) {
        this.form.status_id = 2;
      }
      this.loading = true;
      axios.post('/licenses/store_or_update_all_data', this.form).then((response) => {
        if (!this.form.id) {
          this.$emit('addEmit', response.data);
        } else {
          this.$emit('editEmit', response.data);
        }
        this.closeDialog();
      }).catch(error => {
        this.loading = false;
        if (!error || !error.response) {
          Vue.swal({
            type: 'error',
            title: this.$t('validation.error'),
            text: this.$t('validation.error_exists'),
            confirmButtonColor: '#0056b3'
          })
          return;
        }
        if (error.response.status === 401) {
          Vue.swal({
            type: 'info',
            title: "Info",
            text: this.$t('validation.session'),
            confirmButtonColor: '#0056b3'
          })
          this.$router.push('/login')
        }
      })
    },

    getPartners() {
      axios.post('/partners/index', this.params).then(response => {
        this.partners = response.data;
      }).catch(error => {
        console.log(error);
      });
    },

    getPlaces() {
      this.loadingPlaces = true;
      axios.post('/places/partner_places', {
        partner_id: this.form.partner_id,
        show_only_active: true
      }).then(response => {
        this.places = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loadingPlaces = false;
      })
    },
    getStatuses() {
      axios.post('/statuses/index', {
        show_only_active: true
      }).then(response => {
        this.statuses = response.data;
      }).catch(error => {
        console.log(error);
      })
    },

    async loadData(isEdit) {
      this.getPartners();
      if (isEdit) {
        this.getPlaces();
        this.getStatuses();
      }

    }
  },
  watch: {
    'form.partner_id': function (value) {
      this.form.place_id = null;
      if (value) {
        this.getPlaces();
      } else {
        this.places = null;
      }
    },
    'datePickerFrom': function (value) {
      if (value) {
        this.form.available_from = this.formatDate(this.datePickerFrom, '00:00:00');
        this.isVisibleFrom = false
      } else {
        this.isVisibleFrom = true
      }
    },
    'datePickerTo': function (value) {
      if (value) {
        this.form.available_to = this.formatDate(this.datePickerTo, '23:59:59');
        this.isVisibleTo = false
      } else {
        this.isVisibleTo = true
      }
    }
  },

  async created() {
    if (this.dataForDialog) {
      this.form.partner_id = this.dataForDialog.partner_id;
      this.form.place_id = this.dataForDialog.place_id;
      this.form.id = this.dataForDialog.id
    }
    await this.loadData(!!this.dataForDialog);
  }
}
</script>

<style scoped>
.main-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1
}
</style>