<template>
  <v-app-bar app style="background-color: #0C356A">
    <v-layout justify-space-between>
      <v-app-bar-nav-icon @click="toggleDrawer" style="color:#fff; transform: scale(1.2)"/>
      <template>
        <div class="text-center">
          <v-menu
              offset-y
              dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mt-2 primary warning"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ name }}
              </v-btn>
            </template>
            <v-list
                class="d-flex flex-column"
                v-for="item in links"
                :key="item.id"
            >
              <v-list-item class="d-flex align-center" @click="handleClick(item.path)">
                <v-icon class="pr-3">{{item.icon}}</v-icon>
                <v-list-item-title class="pt-1 text-subtitle-2">{{item.name}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-layout>

  </v-app-bar>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "AppBar",
  methods: {
    ...mapMutations(['toggleDrawer']),
    handleClick(path) {
      if (path === '/logout') {
        this.$store.dispatch('logout');
        this.$router.push('/login');
      } else {
        this.$router.push(path);
      }
    }
  },
  data() {
    return {
      links: [
        {
          id: 1,
          path: "/userSettings",
          name: this.$t('navigationDrawer.profile'),
          icon: " mdi-account-circle"
        },
        {
          id: 2,
          path: "/logout",
          name: this.$t('navigationDrawer.logOut'),
          icon: "mdi-logout"
        }
      ],
      name: ""
    }
  },
  mounted() {
    this.name = localStorage.getItem('name');
  }
}
</script>

<style scoped>

</style>