<template>
<div>
      <core-app-bar/>
      <core-drawer/>
      <v-main>
        <router-view></router-view>
      </v-main>
</div>
</template>

<script>
import AppBar from "@/components/core/appBar/appBar.vue";
import NavigationDrawer from "@/components/core/navigationDrawer/navigationDrawer.vue";

export default {
  name: "DashboardLayout",
  components: {
    'core-app-bar' : AppBar,
    'core-drawer' : NavigationDrawer
  }
}
</script>

<style scoped>

</style>