import axios from "axios";
import Vue from "vue";

function showSnackbar(text, color = 'success') {
    this.snackbar.text = text;
    this.snackbar.color = 'success';
    this.snackbar.show = true;
}

function handleSelectFilters(key, value, filters) {
    // Ako se proslijedi kljuc, znaci da zelimo vec
    // izabran filter, pa provjeravamo da li postoji
    // takav u objektu filters u komponenti
    // Ako postoji postavljamo mu vrijednost
    if (!filters) {
        filters = {};
    }
    let customFilter = false
    Object.keys(filters).forEach(f => {
        if (key && value !== undefined) {
            if (f === key) {
                filters[f] = value
                customFilter = true
            }
        } else {
            filters[f] = ''
        }
    });
    return customFilter;
}

/*---------------------------------- univerzalni read, get od api  ----------------------------------------*/
export function readTableData(api, params, filters) {
    this.tableDataLoading = true;
    this.tableData = [];

    axios.post(api, params)
        .then(response => {
            this.tableDataLoading = false;
            this.tableData = response.data;
            this.filteredTableData = response.data;

            // Ako ova metoda vrati true desio se custom filter
            // pa treba raditi pretragu
            if (filters.key !== undefined && filters.value !== undefined) {
                if (handleSelectFilters(filters.key, filters.value, filters)) {
                    this.search();
                }
            }
        })
        .catch(error => {
            console.log(error);
            this.tableDataLoading = false;
        });
}

/*--------------------------------Univerzalni read za bilo sta---------------------------------*/
export function read(api, filters) {
    this.tableDataLoading = true
    axios.get(api).then(response => {
        this.tableDataLoading = false
        this.tableData = response.data;
        this.filteredTableData = response.data;
        if (filters.key !== undefined && filters.value !== undefined) {
            if (handleSelectFilters(filters.key, filters.value, this.filters)) {
                this.search();
            }
        }
    }).catch(error => {
        this.tableDataLoading = false
        return error.response;
    })
}

/*---------------------------------- univerzalni search  ----------------------------------------*/
export function search() {
    // console.log('filteredTableData', this.filteredTableData)
    if (this.filteredTableData.length === 0) {
        this.filteredTableData = this.tableData
    }
    this.tableData = this.filteredTableData
    Object.keys(this.filters).forEach(val => {
        if (this.filters[val] === "") {
            return;
        }
        this.tableData = this.tableData.filter(item => {
            if (item !== undefined && item !== null) {
                //kreiraj varijablu koja sadrzi item
                let value = item;
                //ukoliko val koji predstavlja kljuc iz fitlera sadrzi tacku,
                //to znaci da treba uci u objekat, pa se to mora raditi preko []
                if (val.indexOf(".") !== -1) {
                    //dobijamo svaki kljuc
                    let keys = val.split(".")
                    //prolazimo kroz svaki kljuc i ulazimo u value kroz loop i value dobija vrijednost od kljuca
                    keys.forEach(part => {
                        if (value !== null) {
                            value = value[part];
                        }
                    })
                } else {
                    //ako nema tacke onda samo postaje kao sto pise
                    value = item[val];
                }
                return (value + "").toLowerCase().indexOf((this.filters[val] + "").toLowerCase()) > -1
            }
        })
    });
    if (this.tableData.length === 0) {
        this.errorMessage = this.$t('no_data');
    }
}

/*---------------------------------- refresh za filtere  ----------------------------------------*/
export function refresh(isReset) {
    if (this.filteredTableData.length === 0) {
        this.filteredTableData = this.tableData
    }
    Object.keys(this.filters).forEach(f => {
        this.filters[f] = ''
    });
    if (isReset) {
        this.tableData = this.filteredTableData
    } else {
        this.readTableData(this.api);
    }
}

/*---------------------------------- univerzalni emit za dodavanje  ----------------------------------------*/
function addEmit(item) {
    this.tableData.push(item)
    this.snackbar.color = 'success';
    this.snackbar.text = this.$t('general.add_success');
    this.snackbar.show = true;
}

/*---------------------------------- univerzalni emit za edit  ----------------------------------------*/
function editEmit(item) {
    this.tableData.splice(this.findById(item.id, this.tableData), 1, item)
    this.snackbar.color = 'success';
    this.snackbar.text = this.$t('general.edit_success');
    this.snackbar.show = true;
}

/*---------------------------------- univerzalni delete, delete od api + item  ----------------------------------------*/
function onDelete(api, item,) {
    this.deleteLoadingId = item.id
    let message = this.$t('general.deactivate')
    if (!item.is_active) {
        message = this.$t('general.reactivate')
    }
    Vue.swal({
        title: message,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff5252",
        reverseButtons: false,
        cancelButtonText: this.$t('general.deny'),
        confirmButtonText: this.$t('general.confirm')
    }).then(result => {
        if (!result.value) {
            this.deleteLoadingId = -1;
            return;
        }
        axios.delete(api + '/' + item.id)
            .then(({data}) => {
                item.is_active = data.is_active ? true : false;
                item = data;
                this.snackbar.color = 'success'
                this.snackbar.text = this.$t('general.edit_success');
                this.snackbar.show = true;
                this.deleteLoadingId = -1;
            }).catch(error => {
            this.deleteLoadingId = -1;
            if (error.response.status === 401) {
                this.loading = false
                Vue.swal({
                    type: 'info',
                    title: "Info",
                    text: this.$t('validation.session'),
                })
                this.$router.push('/login')
            } else if (error.response.status === 422) {
                Vue.swal({
                    type: 'error',
                    title: this.$t('validation.error'),
                    text: error.response.data.message,
                })
            } else {
                Vue.swal({
                    type: 'error',
                    title: this.$t('validation.error'),
                    text: this.$t('validation.error'),
                })
            }
        });
    });
}

/*---------------------------------- univerzalna pomocna metoda za trazenje pozicije u nizu  ----------------------------------------*/
function findById(id, array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id == id) {
            return i;
        }
    }
    return -1;
}

/*---------------------------------- univerzalna metoda za formatiranje datuma u formatu YYYY-MM-DD HH:mm:ss  ----------------------------------------*/
function formatDate(datePicker, time) {
    let selectedDate = new Date(datePicker);

    let year = selectedDate.getFullYear();
    let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    let day = selectedDate.getDate().toString().padStart(2, '0');

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate + ' ' + time;
}

export {
    showSnackbar,
    addEmit,
    editEmit,
    findById,
    onDelete,
    formatDate
}

const style = document.createElement('style');
style.textContent = `
.v-snack__content{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}`;
document.head.appendChild(style);