import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state : {
        drawer: true,
        token: "",
        isAuthenticated: false,
        name: ""
    },
    mutations: {
        toggleDrawer (state) {
            state.drawer = !state.drawer;
        },
        setToken(state, token) {
            state.token = token;
        },
        setIsAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setName(state, name) {
            state.name = name;
        },
        setExpiration(state, expiration) {
            state.expiration = expiration;
        }
    },

    actions: {
        setToken({ commit }, token) {
            localStorage.setItem('token', token);
            commit('setToken', token);
        },
        setIsAuthenticated({ commit }, isAuthenticated) {
            localStorage.setItem('isAuthenticated', isAuthenticated);
            commit('setIsAuthenticated', isAuthenticated);
        },
        setName({ commit }, name) {
            localStorage.setItem('name', name);
            commit('setName', name);
        },
        setExpiration({ commit }, expiration) {
            localStorage.setItem('expiration', expiration);
            commit('setExpiration', expiration);
        },

        logout({ commit }) {
            localStorage.clear();
            commit('setToken', "");
            commit('setIsAuthenticated', false);
            commit('setName', "");
            commit('setExpiration', "");
        }
    },
})
export default store