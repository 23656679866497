import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0056b3',
                secondary: '#4caf50',
                tertiary: '#495057',
                accent: '#82B1FF',
                error: '#f55a4e',
                info: '#00d3ee',
                success: '#3F6ECA',
                warning: '#ffa21a'
            }
        }
    },
    icons: {
        iconfont: 'mdi'
    }
});
