<template>
  <v-navigation-drawer
      v-model="$store.state.drawer"
      app
      style="background-color: #001F3F"
  >
    <v-list-item>
        <img src="@/assets/app_logo.svg" alt="Logo"/>
    </v-list-item>
    <v-list
        flat
        shaped
        class="pt-5"
    >
      <v-list-item-group
           class="d-flex flex-column"
          v-for="item in links"
          :key="item.id"
      >
        <v-list-item
            :class="{ 'active-link': $route.path === item.path}"
            class="d-flex align-center mb-3"
            @click="$router.push(item.path)"
        >
          <v-icon class="pr-3" size="33" style="color:#fff">{{item.icon}}</v-icon>
          <v-list-item-title class="pt-1 text-subtitle-1" style="color:#fff;">{{item.name}}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "NavigationDrawer",
  data() {
    return {
      links: [
        {
          id: 1,
          path: "/partners",
          name: this.$t('partnersPage.partners'),
          icon: "mdi-account-group"
        },
        {
          id: 2,
          path: "/licenses",
          name: this.$t('licensesPage.licenses'),
          icon: "mdi-license"
        },
        {
          id: 3,
          path: "/countries",
          name: this.$t('countriesPage.countries'),
          icon: "mdi-flag"
        },
        {
          id: 4,
          path: "/cities",
          name: this.$t('citiesPage.cities'),
          icon: "mdi-city"
        }
      ],
    }
  }
}
</script>

<style scoped>
.active-link {
  background-color: #0056b3;
}
</style>