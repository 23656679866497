<template>
  <v-container fluid>
    <v-row justify="center" align="center" class="mt-16">
      <v-col cols="12" class="text-center">
        <h1 class="display-3">404</h1>
        <p class="subtitle-1">Oops, the page you're looking for doesn't exist.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
</style>
