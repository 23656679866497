<template>
  <div class="pt-16">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <v-form @submit.prevent="submitForm" v-show="showLogin" ref="form" lazy-validation>
          <div>
            <img src="@/assets/app_logo.svg" alt="Logo" style="width: 250px"/>
          </div>
          <v-text-field
              @keydown.enter="submitForm"
              v-model="username"
              class="pl-3 pr-3"
              :label="$t('homePage.username')"
              :rules="nameRules"
              required
              autocomplete="username"
          />
          <v-text-field
              @keydown.enter="submitForm"
              v-model="password"
              class="pl-3 pr-3 pb-0"
              type="password"
              :label="$t('homePage.password')"
              :rules="passwordRules"
              required
              autocomplete="current-password"
          />
          <v-btn
              class="mb-3"
              :loading="loading"
              color="success"
              @click="submitForm"
          >
            {{ $t('homePage.login') }}
          </v-btn>
        </v-form>
      </div>
    </div>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  data() {
    return {
      showLogin: true,
      username: '',
      password: '',
      loading: false,
      nameRules: [
        v => !!v || this.$t('validation.required_fields'),
      ],
      passwordRules: [
        v => !!v || this.$t('validation.required_fields'),
      ],
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },
  methods: {
    submitForm() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.performLogin();
    },
    performLogin() {
      const data = {
        username: this.username,
        password: this.password
      };
      this.loading = true;
      try {
        axios.post('login', data).then((response) => {
          this.$store.dispatch('setToken', response.data.token);
          this.$store.dispatch('setIsAuthenticated', !!response.data.token);
          this.$store.dispatch('setName', response.data.name);
          this.$store.dispatch('setExpiration', response.data.expires_in + Date.now());
          if (response.status === 200) {
            this.$router.push('/dashboard');
          } else if (response.status === 210) {
            this.showLogin = false;
          }
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
          }
          if (error.response.status === 422) {
            Vue.swal({
              type: 'error',
              title: "Error",
              text: error.response.data.message,
              confirmButtonColor: '#0056b3'
            })
          }
        })
      }catch (error){
        console.log(error)
      }
    }
  }
};
</script>

<style lang="css" scoped>
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 5px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 80%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=text]::placeholder {
  color: #cccccc;
}

input[type=password] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 80%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=password]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=password]::placeholder {
  color: #cccccc;
}

/* ANIMATIONS */
/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%
}

h1 {
  color: #60a0ff;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 30%;
}

</style>
