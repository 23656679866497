<template>
  <v-container
      fluid
      grid-list-sm
  >
    <v-toolbar
        color="white"
    >
      <v-toolbar-title
          class="headline"
      >
        {{ $t('countriesPage.countries') }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn
          v-show="!tableDataLoading"
          @click="openCountryDialog(true, null)"
          class="mr-4"
          color="primary"
          dark
      >
        {{ $t('countriesPage.add_country') }}
      </v-btn>
      <v-btn
          @click="readTableData(api, params)"
          :loading="tableDataLoading"
          :disabled="tableDataLoading"
          color="warning"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-spacer/>
    <v-data-table
        :mobile-breakpoint="breakpoint"
        :headers="headersFinal"
        :items="tableData"
        :items-per-page="itemsPerPage"
        :footer-props="{itemsPerPageOptions: [10,20,30,50,-1]}"
        :loading-text="$t('partnersPage.loading_data')"
        :loading="tableDataLoading"
        :key="anIncreasingNumber"
        :dense="true"
        class="elevation-1"
        sort-by="name"
    >
      <template
          v-slot:[`item.is_active`]="{ item }"
      >
        <v-chip
            small
            :class="{'error inactive-row white--text':!item.is_active, 'white black--text':item.is_active}"
        >
          {{ item.is_active ? $t('countriesPage.is_active') : $t('countriesPage.not_active') }}
        </v-chip>
      </template>
      <template
          v-slot:[`item.actions`]="{ item }"
      >
        <v-layout
            align-center
            justify-center
        >
          <v-tooltip
              bottom
          >
            <template
                v-slot:activator="{ on, attrs }"
            >
              <v-btn
                  @click.stop="openCountryDialog(true, item)"
                  v-bind="attrs"
                  v-on="on"
                  icon
              >
                <v-icon
                    style="color: #ffa21a"
                >
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('countriesPage.edit_country') }}
            </span>
          </v-tooltip>
          <v-tooltip
              bottom
          >
            <template
                v-slot:activator="{ on, attrs }"
            >
              <v-btn
                  @click.stop="onDelete(destroyApi,item)"
                  :loading="deleteLoadingId === item.id"
                  v-bind="attrs"
                  v-on="on"
                  icon
              >
                <v-icon
                    v-if="item.is_active"
                    class="red--text"
                >
                  mdi-trash-can-outline
                </v-icon>
                <v-icon
                    v-else
                >
                  mdi-autorenew
                </v-icon>
              </v-btn>
            </template>
            <span>
             {{ item.is_active ? $t('countriesPage.deactivate_country') : $t('countriesPage.reactivate_country') }}
            </span>
          </v-tooltip>
        </v-layout>
      </template>
      <template
          v-slot:[`body.prepend`]
      >
        <tr
            class="grey lighten-2"
        >
          <th
              v-for="header in headers"
              :key="header.name"
              class="py-1"
          >
            <v-select
                v-if="header.name === 'active'"
                v-model="filters[header.value]"
                @change="search"
                :items="activeObjects"
                item-value="value"
                item-text="text"
                style="padding: 0; margin-bottom: -1em !important; font-size: inherit; text-align: right !important;"
                class="justify-center"
            />
            <v-text-field
                v-model="filters[header.value]"
                v-else-if="header.name !== 'actions'"
                @keyup.enter.native="search"
                :placeholder="header.text"
                hide-details
                class="filter-input"
            />
            <v-layout
                v-else class="justify-center"
            >
              <v-btn
                  @click="search"
                  :disabled="tableDataLoading"
                  color="primary"
                  depressed
                  small
              >
                <v-icon>
                  mdi-search-web
                </v-icon>
              </v-btn>
              <v-btn
                  @click="refresh(true)"
                  :disabled="tableDataLoading"
                  color="red"
                  class="ml-1"
                  small
                  depressed
              >
                <v-icon
                    color="white"
                >
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </v-layout>
          </th>
        </tr>
      </template>
      <template
          v-slot:[`no-data`]
      >
        <div
            class="text-xs-center pt-3"
        >
          <v-alert
              color="red"
          >
            <span
                class="flex items-center justify-center"
                style="color: white"
            >
              <span>
                {{ $t('countriesPage.no_data') }}
              </span>
              <v-icon
                  class="white-icon ml-2"
              >
                mdi-alert-circle-outline
              </v-icon>
            </span>
          </v-alert>
        </div>
      </template>
    </v-data-table>
    <country-update-and-add-dialog
        v-if="isDialogVisible"
        :isDialogVisible="isDialogVisible"
        :dataForDialog="dataForDialog"
        @close="isDialogVisible=false"
        @addEmit="addEmit"
        @editEmit="editEmit"
    />
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  addEmit,
  editEmit,
  readTableData,
  refresh, search,
  findById,
  onDelete
} from "@/statics/DataTableFunctions";
import CountryUpdateAndAddDialog from "@/components/countries/countryAddAndUpdateDialog/countryAddAndUpdateDialog.vue";

export default {
  name: "CountriesPage",
  components: {'country-update-and-add-dialog': CountryUpdateAndAddDialog},
  data() {
    return {
      destroyApi: 'countries/destroy',
      breakpoint: 320,
      tableData: [],
      api: 'countries/index',
      params: {"show_only_active": false},
      itemsPerPage: 10,
      tableDataLoading: false,
      anIncreasingNumber: 1,
      filters: {is_active: ''},
      headers: [],
      isDialogVisible: false,
      dataForDialog: {},
      deleteLoadingId: -1,
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    };
  },

  methods: {
    onDelete,
    editEmit,
    addEmit,
    readTableData,
    search,
    refresh,
    findById,
    openCountryDialog(isVisible, item) {
      this.isDialogVisible = isVisible;
      this.dataForDialog = item;
    }
  },

  computed: {
    headersFinal: {
      get() {
        return this.headers;
      },
      set(newValue) {
        this.headers = newValue;
      }
    },
    activeObjects() {
      return [
        {value: "", text: this.$t('countriesPage.all')},
        {value: true, text: this.$t('countriesPage.is_active')},
        {value: false, text: this.$t('countriesPage.not_active')}
      ];
    }
  },

  created() {
    this.headersFinal = [
      {
        text: this.$t('countriesPage.name'),
        name: 'name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: this.$t('countriesPage.active'),
        name: 'active',
        align: 'left',
        sortable: true,
        value: 'is_active'
      },
      {text: this.$t('countriesPage.actions'), name: 'actions', align: 'center', sortable: false, value: 'actions'},
    ];
    this.readTableData(this.api, this.params, {});
  }
}
</script>

<style scoped>
.v-toolbar {
  margin-bottom: 15px;
}

.white-icon {
  color: white;
}

.filter-input {
  padding: 12px 0;
  margin-top: -1em !important;
  font-size: inherit;
  text-align: right !important;
}
</style>