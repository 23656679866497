<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="isDialogVisible"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title
            class="main-header justify-space-between primary mb-4"
        >
          <span
              class="headline"
              style="color: #fff"
          >
            {{ dataForDialog === null ? $t('placesPage.add_place') : $t('placesPage.edit_place') }}
          </span>
          <v-btn
              @click="closeDialog"
              color="white"
              large
              icon
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-on:submit.prevent
                lazy-validation
            >
              <v-row>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('placesPage.name')+'*'"
                      v-model="form.name"
                      :rules="rules.required"
                      :disabled="!cities"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      :label="$t('placesPage.address')+'*'"
                      v-model="form.address"
                      :rules="rules.required"
                      :disabled="!cities"
                      dense
                  />
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-autocomplete
                      v-model="city"
                      :items="cities"
                      :label="$t('placesPage.city')+'*'"
                      return-object
                      :no-data-text="$t('placesPage.no_data')"
                      :rules="rules.required"
                      :disabled="!cities"
                      :loading="!cities"
                      item-text="name"
                      dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>
            {{ $t('placesPage.required_fields') + ' *' }}
          </small>
        </v-card-text>
        <v-card-actions
            class="justify-space-between"
        >
          <v-btn
              @click="clearInputs"
              :disabled="!cities"
              color="red darken-1"
              text
          >
            {{ $t('placesPage.delete') }}
          </v-btn>
          <v-btn
              @click="dataForDialog === null ? add() : edit()"
              :disabled="!cities"
              :loading="loading"
              style="color: #0056b3"
              text
          >
            {{ dataForDialog === null ? $t('placesPage.add') : $t('placesPage.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "PlaceUpdateAndAddDialog",
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    dataForDialog: {
      type: Object,
      default: {}
    },
    partnerId: ''
  },
  data() {
    return {
      loading: false,
      itemHeaders: null,
      cities: null,
      city: null,
      citySearch: '',
      api: 'cities/index',
      cityId: this.dataForDialog !== null ? this.dataForDialog.id : '',
      params: {
        "show_only_active": 1
      },
      form: {
        name: this.dataForDialog != null ? this.dataForDialog.name : '',
        address: this.dataForDialog != null ? this.dataForDialog.address : '',
        city_id: this.dataForDialog != null ? this.dataForDialog.city_id : '',
        partner_id: this.partnerId
      },
      rules: {
        required: [
          v => !!v || this.$t('placesPage.required_fields'),
        ]
      },
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    }
  },
  methods: {
    clearInputs() {
      this.form.name = '';
      this.form.address = '';
      this.citySearch = '';
      this.city = null;
    },

    setCityById(cityId) {
      const foundCity = this.cities.find(city => city.id === cityId);
      if (foundCity) {
        this.city = foundCity;
      }
    },

    getCities() {
      axios.post('/cities/index', this.params).then(response => {
        this.cities = response.data;
        if (this.dataForDialog !== null) {
          this.setCityById(this.dataForDialog.city_id);
        }
      }).catch(error => {
        console.log(error);
      })
    },

    closeDialog() {
      this.form = {
        name: '',
        address: '',
        city_id: ''
      };
      this.$refs.form.resetValidation();
      this.$emit('close');
    },

    add() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.post('/places/store', this.form).then((response) => {
          this.$emit('addEmit', response.data);
          this.closeDialog();
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
            this.$router.push('/login')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    edit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = 'error';
        this.snackbar.text = this.$t('general.snackbar_required');
        this.snackbar.show = true;
        return;
      }
      this.loading = true;
      try {
        axios.put(`/places/update/${this.dataForDialog.id}`, this.form).then((response) => {
          this.$emit('editEmit', response.data)
          this.closeDialog();
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (!error || !error.response) {
            Vue.swal({
              type: 'error',
              title: this.$t('validation.error'),
              text:  this.$t('validation.error_exists'),
              confirmButtonColor: '#0056b3'
            })
            return;
          }
          if (error.response.status === 401) {
            Vue.swal({
              type: 'info',
              title: "Info",
              text: this.$t('validation.session'),
              confirmButtonColor: '#0056b3'
            })
            this.$router.push('/login')
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  created() {
    this.getCities();
  },
  watch: {
    city: {
      handler: function (value) {
        if (value) {
          this.form.city_id = value.id;
        } else {
          this.form.city_id = '';
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>
.main-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1
}
</style>