import Vue from "vue"
import axios from "axios"

axios.defaults.timeout = 180*10000

axios.interceptors.request.use(async (config) => {
    config.baseURL = "/api";
    const token = localStorage.getItem('token')
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => response, (error) => {
    if (!error.response) {
        Vue.swal({
            icon: 'error',
            title: 'Greška',
            text: 'Greška u komunikaciji sa serverom',
        })
        return Promise.reject(error);
    }
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            this.router.push('/login');
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$axios = axios
