<template>
  <v-row
      justify="end"
      class="mr-0"
  >
    <v-dialog
        v-model="isPlacesDialogVisible"
        max-width="600px"
        persistent
        fullscreen
    >
      <v-card>
        <v-toolbar
            color="white"
        >
          <v-toolbar-title
              class="headline"
          >
            {{ partnerName }}
          </v-toolbar-title>
          <v-spacer/>
          <v-btn
              @click="openPlaceDialog(true, null)"
              v-show="!tableDataLoading"
              class="mr-4"
              color="primary"
              dark
          >
            {{ $t('placesPage.add_place') }}
          </v-btn>
          <v-btn
              @click="readTableData(api, params)"
              :loading="tableDataLoading"
              :disabled="tableDataLoading"
              color="warning"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
              @click="closeDialog"
              large
              icon
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer/>
        <v-data-table
            :mobile-breakpoint="breakpoint"
            :headers="headersFinal"
            :items="tableData"
            :items-per-page="itemsPerPage"
            :footer-props="{itemsPerPageOptions: [10,20,30,50,-1]}"
            :loading-text="$t('partnersPage.loading_data')"
            :loading="tableDataLoading"
            :key="anIncreasingNumber"
            class="elevation-1"
            sort-by="name"
            :dense="true"
        >
          <template
              v-slot:[`item.is_active`]="{ item }"
          >
            <v-chip
                small
                :class="{'error inactive-row white--text':!item.is_active, 'white black--text':item.is_active}"
            >
              {{ item.is_active ? $t('countriesPage.is_active') : $t('countriesPage.not_active') }}
            </v-chip>
          </template>
          <template
              v-slot:[`item.actions`]="{ item }"
          >
            <v-layout
                align-center
                justify-center
            >
              <v-tooltip
                  bottom
              >
                <template
                    v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                      @click.stop="openPlaceDialog(true, item)"
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon
                        style="color: #ffa21a"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('placesPage.edit_place') }}
                </span>
              </v-tooltip>
              <v-tooltip
                  bottom
              >
                <template
                    v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                      @click.stop="onDelete(destroyApi,item)"
                      :loading="deleteLoadingId === item.id"
                      v-bind="attrs"
                      v-on="on"
                      icon
                  >
                    <v-icon
                        v-if="item.is_active"
                        class="red--text"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                    <v-icon
                        v-else
                    >
                      mdi-autorenew
                    </v-icon>
                  </v-btn>
                </template>
                <span>
              {{ item.is_active ? $t('placesPage.deactivate_place') : $t('placesPage.reactivate_place') }}
                </span>
              </v-tooltip>
            </v-layout>
          </template>
          <template
              v-slot:[`body.prepend`]
          >
            <tr
                class="grey lighten-2"
            >
              <th
                  v-for="header in headers"
                  :key="header.name"
                  class="py-1"
              >
                <v-select
                    v-if="header.name === 'active'"
                    class="justify-center"
                    v-model="filters[header.value]"
                    @change="search"
                    :items="activeObjects"
                    item-value="value"
                    item-text="text"
                    style="padding: 0; margin-bottom: -1em !important; font-size: inherit; text-align: right !important;">
                </v-select>
                <v-text-field
                    v-else-if="header.name !== 'actions'"
                    v-model="filters[header.value]"
                    @keyup.enter.native="search"
                    :placeholder="header.text"
                    hide-details
                    class="filter-input"
                />
                <v-layout
                    v-else
                    class="justify-center"
                >
                  <v-btn
                      @click="search"
                      :disabled="tableDataLoading"
                      depressed
                      small
                      color="primary"
                  >
                    <v-icon>
                      mdi-search-web
                    </v-icon>
                  </v-btn>
                  <v-btn
                      @click="refresh(true)"
                      :disabled="tableDataLoading"
                      class="ml-1"
                      color="red"
                      depressed
                      small
                  >
                    <v-icon
                        color="white"
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </v-layout>
              </th>
            </tr>
          </template>
          <template
              v-slot:[`no-data`]
          >
            <div
                class="text-xs-center pt-3"
            >
              <v-alert
                  color="red"
              >
            <span
                class="flex items-center justify-center"
                style="color: white"
            >
              <span>
                {{ $t('countriesPage.no_data') }}
              </span>
              <v-icon
                  class="white-icon ml-2"
              >
                mdi-alert-circle-outline
              </v-icon>
            </span>
              </v-alert>
            </div>
          </template>
        </v-data-table>
        <place-update-and-add-dialog
            v-if="isPlaceDialogVisible"
            :isDialogVisible="isPlaceDialogVisible"
            :dataForDialog="dataForDialog"
            :partnerId="partnerId"
            @close="isPlaceDialogVisible=false"
            @addEmit="addEmit"
            @editEmit="editEmit"
        />
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn
          @click="snackbar.show = false"
          dark
          text
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
import {
  addEmit,
  readTableData,
  refresh,
  search,
  findById,
  editEmit,
  onDelete
} from "@/statics/DataTableFunctions";

import PlaceUpdateAndAddDialog from "@/components/partners/placeUpdateAndAddDialog/placeUpdateAndAddDialog.vue";

export default {
  name: "PlacesDialog",
  components: {'place-update-and-add-dialog': PlaceUpdateAndAddDialog},
  props: {
    isPlacesDialogVisible: {
      type: Boolean,
      default: false
    },
    partnerId: {
      type: Number
    },
    partnerName: {
      type: String
    }
  },
  data() {
    return {
      destroyApi: 'places/destroy',
      breakpoint: 320,
      tableData: [],
      api: 'places/partner_places',
      params: {
        "partner_id": this.partnerId,
        "show_only_active": false
      },
      itemsPerPage: 10,
      tableDataLoading: false,
      anIncreasingNumber: 1,
      filters: {is_active: ''},
      headers: [],
      isPlaceDialogVisible: false,
      dataForDialog: {},
      deleteLoadingId: -1,
      snackbar: {
        color: 'success',
        show: false,
        text: '',
        timeout: 3000,
      },
    };
  },
  methods: {
    onDelete,
    editEmit,
    addEmit,
    readTableData,
    search,
    refresh,
    findById,
    openPlaceDialog(isVisible, item) {
      this.isPlaceDialogVisible = isVisible;
      this.dataForDialog = item;
    },
    closeDialog() {
      this.$emit('close');
    }
  },
  computed: {
    headersFinal: {
      get() {
        return this.headers;
      },
      set(newValue) {
        this.headers = newValue;
      }
    },
    activeObjects() {
      return [
        {value: "", text: this.$t('placesPage.all')},
        {value: true, text: this.$t('placesPage.is_active')},
        {value: false, text: this.$t('placesPage.not_active')}
      ];
    }
  },
  created() {
    this.headersFinal = [
      {
        text: this.$t('placesPage.name'),
        name: 'name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: this.$t('placesPage.address'),
        name: 'address',
        align: 'left',
        sortable: true,
        value: 'address'
      },
      {
        text: this.$t('placesPage.city'),
        name: 'city_name',
        align: 'left',
        sortable: true,
        value: 'city.name'
      },
      {
        text: this.$t('placesPage.active'),
        name: 'active',
        align: 'left',
        sortable: true,
        value: 'is_active'
      },
      {text: this.$t('placesPage.actions'), name: 'actions', align: 'center', sortable: false, value: 'actions'},
    ]
    this.readTableData(this.api, this.params, {});
  }
}
</script>

<style scoped>
.v-toolbar {
  margin-bottom: 15px;
}

.white-icon {
  color: white;
}

.filter-input {
  padding: 12px 0;
  margin-top: -1em !important;
  font-size: inherit;
  text-align: right !important;
}
</style>